import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="#" codeUrl="#" checklists={checklists.componentProgressCircle.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A progress circle component is a visual element that displays the progress of a task or process as a circular indicator.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/android-compose-progress-circle.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 3 variants for Progess Circle`}</p>
    <ol>
      <li parentName="ol">{`Progress Full Circle`}</li>
      <li parentName="ol">{`Progress Half Circle`}</li>
      <li parentName="ol">{`Progress Two Third Circle`}</li>
    </ol>
    <h2>{`Usage`}</h2>
    <h3>{`Progress`}</h3>
    <h4>{`Full Circle`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnProgressCircle(
    indicatorValue = 30,
    type = LgnProgressCircleType.FullCircle
) {
    Text(
        text = "Users",
        style = LgnProgressCircleTokens
                .LabelFont
                .toTextStyle()
                .copy(
                    color = LgnProgressCircleTokens
                            .LabelColor
                            .toColor()
                )
    )

    Text(
        text = "30 %",
        textAlign = TextAlign.Center,
        style = LgnProgressCircleTokens
                .ContentFont
                .toTextStyle()
    )
}
`}</code></pre>
    <h4>{`Half Circle`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnProgressCircle(
    indicatorValue = 30,
    type = LgnProgressCircleType.HalfCircle,
    canvasWidthSize = 120.dp,
    canvasHeightSize = 60.dp
) {
    Text(
        text = "Users",
        style = LgnProgressCircleTokens
                .LabelFont
                .toTextStyle()
                .copy(
                    color = LgnProgressCircleTokens
                            .LabelColor
                            .toColor()
                )
    )

    Text(
        text = "30 %",
        textAlign = TextAlign.Center,
        style = LgnProgressCircleTokens
                .ContentFont
                .toTextStyle()
    )
}
`}</code></pre>
    <h4>{`Two Third Circle`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnProgressCircle(
    indicatorValue = 30,
    type = LgnProgressCircleType.TwoThirdsCircle
) {
    Text(
        text = "Users",
        style = LgnProgressCircleTokens
                .LabelFont
                .toTextStyle()
                .copy(
                    color = LgnProgressCircleTokens
                            .LabelColor
                            .toColor()
                )
    )

    Text(
        text = "30 %",
        textAlign = TextAlign.Center,
        style = LgnProgressCircleTokens
                .ContentFont
                .toTextStyle()
    )
}
`}</code></pre>
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Descriptions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`canvasWidthSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Progress Cyrcle Width Size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`canvasHeightSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Progress Cyrcle Height Size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`indicatorValue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Value of Progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxIndicatorValue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Max Value of Progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnProgressCircleColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Color of Progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stroke`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnProgressCircleStrokeWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Stroke Size of Progess`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnProgressCircleType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Type of Progress Circle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Content in Progress Circle`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      